 .container {
  padding: 40px 0;
}

 .title {
  composes: appSubtitle from global;
}

 .store {
  composes: content from global;
  text-align: left;
  padding: 0 !important;
  margin: 10px 0;
  height: 90%
}

 .inner {
  padding: 20px;
}

 .name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

 .phone {
  margin-bottom: 10px;
}
 .phone a {
  color: #111;
}
